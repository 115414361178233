<template>
  <div>
    <paymentPage></paymentPage>
  </div>
</template>

<script>
const paymentPage = () =>
  import("../../../components/dashboard/patientDashboard/paymentPage");
export default {
  name: "payment",
  components: { paymentPage },
};
</script>

<style scoped></style>
